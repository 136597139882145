<template>
  <div class="wrapper">
    <div class="disclaimer-select unprint" :class="{ active: isShowOptions }" v-click-outside="hideOptions">
      <div @click="isShowOptions = !isShowOptions" class="disclaimer-select__box">
        <div class="disclaimer-select__btn">
          <img :src="selectImg" alt="" />
        </div>
      </div>
      <div class="options" v-if="isShowOptions">
        <label :for="`disclaimer-${i}`" v-for="(_, i) in value" :key="`disclaimer-${i}`">
          <input
            hidden
            type="radio"
            :value="i"
            :id="`disclaimer-${i}`"
            @change="changeOption(i)"
          />
          <span class="name">Примечание {{ i + 1 }}</span>
        </label>
      </div>
    </div>
    <div class="disclaimer" v-html="text"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Disclaimer',
  props: {
    value: Array
  },
  data: () => {
    return {
      isShowOptions: false
    }
  },
  computed: {
    ...mapState({
      disclaimerIndex: state => state.disclaimerIndex
    }),
    selectImg() {
      return this.isShowOptions
        ? require('@/assets/img/location/chevron.svg')
        : require('@/assets/img/location/menu.svg')
    },
    text() {
      return this.value ? this.value[this.disclaimerIndex] : ''
    }
  },
  methods: {
    hideOptions() {
      if (this.isShowOptions) this.isShowOptions = false
    },
    changeOption(index) {
      this.hideOptions()
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  width: 100%
  position: relative
  margin-top: rem(15)
  +media((margin: (0: rem(51) rem(24) 0, 992: rem(51) 0 0)))
.disclaimer
  font-style: normal
  font-weight: normal
  font-size: rem(12)
  line-height: 180%
  color: $black
  text-align: left
  &::v-deep
    > *
        &:first-child
          margin-top: 0
        &:last-child
          margin-bottom: 0
  &::v-deep
    a
      color: $red
  &-select
    margin-right: rem(5)
    position: absolute
    right: 0
    height: rem(40)
    font-family: $main-font
    font-style: normal
    font-weight: bold
    line-height: rem(15)
    font-size: rem(14)
    cursor: pointer
    &__btn
      filter: invert(17%) sepia(0%) saturate(0%) hue-rotate(44deg) brightness(93%) contrast(87%)
      path
        transition: .5s
    .options
      width: rem(172)
      color: $black
      display: flex
      flex-direction: column
      background: $white
      z-index: 3
      border: rem(1) solid $grey
      border-radius: rem(4) rem(4) 0 0
      position: absolute
      box-sizing: border-box
      bottom: 100%
      right: 0
      label
        position: relative
        font-style: normal
        font-weight: bold
        font-size: rem(14)
        line-height: rem(22)
        opacity: 0.9
        text-align: left
        height: rem(34)
        box-shadow: inset 0 rem(-1) 0 $ec
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 0 rem(14)
        transition: .5s
        cursor: pointer
        width: 100%
        &:hover
          color: $red
        .name
          min-width: rem(36)
</style>
