<template>
  <div class="tables__item-head">
    <div class="image">
      <img :src="currentMaterial.src" :alt="currentMaterial.name" />
    </div>
    <div class="title">{{ currentMaterial.name }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    id: {
      type: Number,
      required: true,
      default: 0
    }
  },
  name: 'ItemHead',
  computed: {
    ...mapState({
      allMaterials: state => state.materials
    }),
    currentMaterial() {
      return this.allMaterials.find(material => material.id === this.id)
    }
  }
}
</script>

<style scoped lang="sass">
.tables
  &__item
    &-head
      // max-width: 100vw
      // position: sticky
      // position: -webkit-sticky
      // left: 0
      background: $white
      border-radius: rem(4) rem(4) 0 0
      display: flex
      align-items: center
      padding: rem(17) rem(20)
      box-sizing: border-box
      // +media((margin: (0: 0 rem(15), 768: 0 rem(20))))
      box-shadow: inset 0 rem(-1) 0 $ec
      // position: relative
      // &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        width: 100%
        height: rem(1)
        background: $ec
        pointer-events: none
      .image
        max-width: rem(100)
        flex: 0 0 rem(100)
        height: auto
        line-height: 0
        img
          max-width: 100%
          height: auto
      .title
        text-align: left
        +media((margin-left: (0: rem(26), 768: rem(40))))
        font-style: normal
        font-weight: bold
        line-height: 100%
        color: $black
        +media((font-size: (320: rem(14), 768: rem(18))))
</style>
