<template>
  <div class="container result-materials">
    <div class="head-title common__title-result">Ширина рулона гидроизоляции</div>
    <div class="tables">
      <div class="tables__item" v-for="(item, i) in slicedResult" :key="`result-${i}`">
        <item-head :id="item.id" />
        <div class="scroll-container">
          <head-titles />
          <div class="tables__item-body">
            <body-item
              v-for="(block, n) in item.sectors"
              :block="block"
              :key="`item--${i}-block--${n}-uid--${block.uid}`"
            />
          </div>
        </div>
      </div>
    </div>
    <expand-btn :is-show-all="isShowAll" @toggle="isShowAll = !isShowAll" v-if="isShowExpandBtn" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import BodyItem from '@/components/dump/Result/MaterialsComputation/BodyItem'
import ExpandBtn from '@/components/dump/Result/MaterialsComputation/ExpandBtn'
import ItemHead from '@/components/dump/Result/MaterialsComputation/ItemHead'
import HeadTitles from '@/components/dump/Result/MaterialsComputation/HeadTitles'

const materialsLimit = 3

export default {
  components: { HeadTitles, ItemHead, ExpandBtn, BodyItem },
  props: {
    resultMaterials: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  name: 'MaterialsComputation',
  data() {
    return {
      showAccordionNav: null,
      isShowAll: false
    }
  },
  computed: {
    ...mapState({
      sectors: state => state.sectors
    }),
    isShowExpandBtn() {
      return this.resultMaterials.length > materialsLimit
    },
    slicedResult() {
      if (this.isShowAll) {
        return this.resultMaterials
      }
      return this.resultMaterials.slice(0, materialsLimit)
    }
  }
}
</script>
<style lang="sass" scoped>
.head-title
  +media((margin: (0: rem(34) 0, 768: rem(51) rem(9) rem(34), 992: rem(51) rem(-15) rem(34))))
  padding: 0
.tables
  display: grid
  +media((grid-row-gap: (0: rem(24), 768: rem(34))))
  margin: 0 rem(-15)
  &__item
    display: grid
    &-body
      background: $semi-white
    .scroll-container
      overflow-x: auto
      display: grid
      max-width: 100%
</style>
