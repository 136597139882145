const colorHashes = {
  red: '#E00F20',
  yellow: '#F5BD2D',
  green: '#3DA915'
}
export const gradesValues = {
  bad: 'bad',
  neutral: 'neutral',
  good: 'good',
  idea: 'idea'
}

export const colors = {
  [gradesValues.bad]: colorHashes.red,
  [gradesValues.neutral]: colorHashes.yellow,
  [gradesValues.good]: colorHashes.green,
  [gradesValues.idea]: colorHashes.red
}

export const fields = {
  name: {
    name: 'name',
    placeholder: 'Укажите ваше имя',
    label: 'Имя'
  },
  email: {
    name: 'email',
    placeholder: 'Укажите вашу эл. почту',
    label: 'Электронная почта'
  },
  message: {
    name: 'message',
    placeholder: 'Пожалуйста, оставьте ваши пожелания для улучшения работы сервиса ',
    label: 'Комментарий',
    textarea: true
  }
}

export const errors = {
  required: 'Поле обязательно',
  email: 'E-mail не валиден'
}[({ uid: 'mzi3iy0oc', C: 11, D: 11, E: 11 }, { uid: 'mzi3iy0oc', C: 12, D: 12, E: 12 })]
