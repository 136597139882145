<template>
  <component :is="currentType" :path="to" :url="url" :class="classes" @btnClicked="handler">
    <slot name="icon"></slot>
    <slot></slot>
  </component>
</template>

<script>
// import MyBtn from '@/components/dump/Page-elements/MyBtn'
// import MyLink from '@/components/dump/Page-elements/MyLink'
const types = {
  btn: () => import('@/components/dump/Page-elements/MyBtn'),
  routerLink: () => import('@/components/dump/Page-elements/MyRouterLink'),
  link: () => import('@/components/dump/Page-elements/MyLink')
}
export default {
  props: {
    to: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: '',
      validator: v => ['red', ''].includes(v)
    },
    simplified: {
      type: Boolean
    },
    reversed: {
      type: Boolean
    }
  },
  name: 'SimpleBtn',
  methods: {
    handler() {
      this.$emit('btnClicked')
    }
  },
  computed: {
    // types: () => types,
    currentType() {
      if (this.to || this.url) {
        return this.to ? types.routerLink : types.link
      }
      return types.btn
    },
    classes() {
      return {
        [`simple-btn--${this.color}`]: this.color,
        [`simple-btn--simplified`]: this.simplified,
        [`simple-btn--reverse`]: this.reversed
      }
    }
  }
}
</script>

<style scoped lang="sass">
.simple-btn
  @extend %clear-btn
  font-family: $main-font
  display: inline-flex
  align-items: center
  min-height: rem(64)
  background: $light-grey
  @extend %14
  color: $black_A
  text-decoration: none
  font-weight: normal
  +media((padding: (320: rem(15) rem(20), 768: rem(12) rem(24))))
  box-sizing: border-box
  transition: .5s
  cursor: pointer
  text-align: left
  @media (any-hover: hover)
    &:hover
      background: $white
  &--red
    background: $red_A
    color: $white
    @media (any-hover: hover)
      &:hover
        background: $red_B
  &--simplified
    +media((min-height: (320: rem(48), 768: rem(56))))
    border-radius: rem(4)
    +media((padding: (320: rem(15) rem(20), 768: rem(18) rem(20))))
</style>
<style lang="sass">
.simple-btn
  .icon
    +media((margin-right: (320: rem(18), 768: rem(24))))
    line-height: 0
  &--simplified
    .icon
      +media((margin-right: (320: rem(10), 768: rem(24))))
</style>
