<template>
  <section class="result">
    <loading
      :active.sync="computedIsLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="fullPage"
    ></loading>
    <div class="container">
      <div class="row">
        <SaveNotification
          v-if="saveCalculationLink && publicLink"
          :link="saveCalculationLink"
          :publicLink="publicLink"
          :message="resultMessage"
        />
      </div>
      <div class="row">
        <div class="minor__title">Результаты расчета</div>
        <div class="common__pane">
          <div class="common__title result__params-title">Параметры расчета</div>
          <div class="result__params">
            <div class="result__params-item">
              <div class="result__params-item--single">
                <div class="title">Ветровой район</div>
                <div class="value">{{ zoneName }}</div>
              </div>
              <div class="result__params-item--single">
                <div class="title">
                  Нормативное значение<br />
                  давления ветра W0
                </div>
                <div class="value">{{ pressure }}</div>
              </div>
            </div>
            <div class="result__params-item">
              <div class="result__params-item--single">
                <div class="title">Тип местности</div>
                <div class="value">{{ location.type }}</div>
              </div>
              <div class="result__params-item--single">
                <div class="title">Коэффициент надежности<br />по ветровой нагрузке</div>
                <div class="value">{{ location.coefficient }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ResultNav />
    <materials-computation :result-materials="result.materials" v-if="result.materials" />
    <div class="container">
      <div class="row">
        <disclaimer :value="disclaimer" @change="changeDisclaimer" />
      </div>
    </div>
    <Addons :links="result.albums" v-if="isShowAlbums">
      Альбомы и инструкции по материалам и монтажу
    </Addons>
    <Plugins />
    <calculator-rating />
    <Prefooter @toggleLoading="toggleLoading" />
  </section>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapState, mapMutations, mapActions } from 'vuex'
import ResultNav from '@/components/dump/Result/ResultNav.vue'
import Addons from '@/components/smart/Addons.vue'
import Plugins from '@/components/dump/Plugins.vue'
import Prefooter from '@/components/dump/Prefooter.vue'
import MaterialsComputation from '@/components/dump/Result/MaterialsComputation'
import CalculatorRating from '@/components/smart/CalculatorRating'
import { getWindZoneName } from '@/utils/windZone'
import { stepNumbers } from '@/utils/stepNumbers'
import Disclaimer from '@/components/dump/Disclaimer'
import SaveNotification from '@/components/dump/SaveNotification.vue'
import { getPressureText } from '@/components/helper'

export default {
  data: () => ({
    fullPage: true,
    isLoading: false,
    modalOpen: false
  }),
  components: {
    SaveNotification,
    CalculatorRating,
    MaterialsComputation,
    ResultNav,
    Addons,
    Plugins,
    Prefooter,
    Loading,
    Disclaimer
  },
  methods: {
    ...mapMutations({
      UPDATE_RESULT: 'UPDATE_RESULT',
      UPDATE_STATE_OBJECT: 'UPDATE_STATE_OBJECT',
      checkTNTokenAction: 'checkTNToken'
    }),
    ...mapActions({
      getContent: 'getContent',
      requestSavedResult: 'requestSavedResult',
      requestResults: 'requestResults'
    }),
    toggleLoading(value) {
      this.isLoading = value
    },
    getSavedResult(urlQuery) {
      this.requestSavedResult({ urlQuery })
    },
    getResults() {
      if (this.location.zone === '') {
        this.$router.push('/')
      } else {
        this.requestResults()
      }
    },
    updateMetric() {
      this.$emit('updateStep', stepNumbers.result)
    },
    changeDisclaimer(val) {
      this.UPDATE_STATE_OBJECT({ object: 'disclaimerIndex', val })
    }
  },
  watch: {
    result() {
      this.updateMetric()
    }
  },
  mounted() {
    const urlQuery = this.$route.query.object
    if (urlQuery) {
      this.getSavedResult(urlQuery)
    } else {
      this.getResults()
    }
    const { tnt: token } = this.$route.query
    if (token) {
      this.checkTNTokenAction(token)
    }
  },
  computed: {
    ...mapState({
      result: state => state.result.total,
      resultSectors: state => state.result.sectors,
      insulationMaterials: state => state.insulationMaterials,
      allMaterials: state => state.materials,
      sectors: state => state.sectors,
      location: state => state.location,
      userInfo: state => state.userInfo,
      loadingStatus: state => state.pages.result.isLoading,
      disclaimer: state => state.disclaimer,
      saveCalculationLink: state => state.saveCalculationLink,
      messages: state => state.messages,
      publicLinkTemplate: state => state.publicLink
    }),
    resultMessage() {
      return this.messages?.result_under_link || 'Для повторного доступа к результатам расчёта необходимо сохранить ссылку. Обратите внимание, если вы в дальнейшем внесёте изменения в проект, то при повторном сохранении проекта ссылка на расчет будет обновлена.'
    },
    zoneName() {
      return getWindZoneName(this.location.zone)
    },
    computedIsLoading() {
      return this.isLoading || this.loadingStatus
    },
    word() {
      const cases = [2, 0, 1, 1, 1, 2]
      const words = ['участок', 'участка', 'участков']
      // prettier-ignore
      return words[this.sectorsCount % 100 > 4 && this.sectorsCount % 100 < 20 ? 2 : cases[this.sectorsCount % 10 < 5 ? this.sectorsCount % 10 : 5]]
    },
    sectorsCount() {
      let total = 0
      for (let i = 0; i < this.sectors.length; i += 1) {
        total += this.sectors[i].sectorsCount
      }
      return total
    },
    isShowAlbums() {
      return this.result.albums && this.result.albums.length !== 0
    },
    pressure() {
      return getPressureText(this.location)
    },
    publicLink() {
      return this.publicLinkTemplate && this.userInfo.result?.id
        ? `${this.publicLinkTemplate}${this.userInfo.result?.id}`
        : ''
    }
  }
}
</script>
<style lang="sass" scoped>
.result
  &__params
    display: grid
    +media((grid-column-gap: (768: rem(20), 960: rem(80))))
    +media((grid-template-columns: (320: 1fr, 768: 1fr 1fr)))
    &-title
      +media((padding-bottom: (320: rem(24), 960: rem(34))))
    &-item
      text-align: left
      &--single
        display: grid
        font-style: normal
        font-weight: 500
        font-size: rem(14)
        line-height: rem(20)
        color: $black
        +media((grid-template-columns: (320: 8fr 4fr, 480: 1fr 1fr, 768: 7fr 3fr)))
        +media((font-size: (320: rem(13), 960: rem(14))))
        +media((line-height: (320: rem(19), 960: rem(20))))
        +media((padding-bottom: (320: rem(16), 960: rem(20))))
        &:last-of-type
          +media((padding-bottom: (320: rem(16), 960: rem(10))))
        .value
          font-weight: bold
          +media((padding-left: (480: rem(20), 768: 0)))
        .title, .value
          display: flex
          flex-direction: row
          align-items: center
      &:last-of-type
        .result__params-item--single
          &:last-of-type
            +media((padding-bottom: (320: 0, 960: rem(10))))
</style>
