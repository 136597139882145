<template>
  <form @submit.prevent="checkForm" novalidate class="form">
    <span class="title" v-if="isIdeaGrade"> Предложить идею </span>
    <form-input
      v-if="!isGoodGrade"
      :field="fields.message"
      v-model.trim="$v.message.$model"
      @input="delayTouch($v.message)"
      :is-error="$v.message.$error"
    >
      <template #errors>
        <error v-if="!$v.message.required">
          {{ errors.required }}
        </error>
      </template>
    </form-input>
    <form-footer :is-loading="isLoading" :is-need-credentials="!isGoodGrade" />
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import FormInput from '@/components/smart/CalculatorRating/Form-input'
import { fields, errors, gradesValues } from '@/utils/rating'
import Error from '@/components/smart/CalculatorRating/error'
import FormFooter from '@/components/smart/CalculatorRating/Form-footer'

const touchMap = new WeakMap()

const messageValidations = {
  required
}

const totalValidation = {
  message: messageValidations
}

const validationsRules = {
  [gradesValues.good]: {},
  [gradesValues.neutral]: {
    message: {}
  },
  [gradesValues.idea]: totalValidation,
  [gradesValues.bad]: totalValidation
}

export default {
  props: {
    isLoading: {
      type: Boolean
    },
    currentGrade: {
      type: String,
      required: true
    }
  },
  components: { FormFooter, Error, FormInput },
  mixins: [validationMixin],
  validations() {
    return validationsRules[this.currentGrade]
  },
  name: 'Rating-form',
  data: () => ({
    message: ''
  }),
  watch: {
    currentGrade() {
      this.resetValidations()
    }
  },
  methods: {
    async resetValidations() {
      await this.$nextTick()
      this.$v.$reset()
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    checkForm() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const { message, isGoodGrade } = this
        const formData = {
          message
        }
        this.$emit('sendForm', isGoodGrade ? {} : formData)
      }
    }
  },
  computed: {
    fields: () => fields,
    errors: () => errors,
    isGoodGrade() {
      return this.currentGrade === gradesValues.good
    },
    isIdeaGrade() {
      return this.currentGrade === gradesValues.idea
    }
  }
}
</script>
<style lang="sass" scoped>
.form
  margin-top: rem(24)
  display: grid
  +media((grid-template-column: (768: 1fr 1fr)))
  grid-column-gap: rem(30)
  +media((grid-row-gap: (0: rem(20), 768: rem(25))))
  .title
    +media((grid-column: (768: span 2)))
    @extend %20
    font-weight: bold
</style>
