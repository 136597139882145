<template>
  <div class="calculator-rating">
    <div class="calculator-rating__title">Оцените работу калькулятора</div>
    <success-message v-if="isShowSuccess">
      {{ successText }}
    </success-message>
    <template v-else>
      <rating-grades :current-rating="currentRating" @updateGrade="updateGrade" />
      <rating-form
        :current-grade="currentRating"
        :is-loading="isLoading"
        @sendForm="sendForm"
        v-if="currentRating"
      />
    </template>
  </div>
</template>

<script>
import { gradesValues } from '@/utils/rating'
import RatingGrades from '@/components/smart/CalculatorRating/RatingGrades'
import { sendRating } from '@/api'
import SuccessMessage from '@/components/smart/CalculatorRating/Success-message'
import RatingForm from '@/components/smart/CalculatorRating/Rating-form'

export default {
  name: 'CalculatorRating',
  data: () => ({
    currentRating: null,
    isLoading: false,
    isShowSuccess: false
  }),
  methods: {
    updateGrade(grade) {
      this.currentRating = grade
    },
    sendForm(fields) {
      this.isLoading = true
      const formData = {
        form: 'CalculatorRating',
        fields: {
          ...fields,
          rating: this.currentRating,
          name: 'Калькулятор ветровой нагрузки на кровлю'
        }
      }
      sendRating(formData, this.hash)
        .then(() => {
          this.isLoading = false
          this.isShowSuccess = true
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  },
  components: { RatingForm, SuccessMessage, RatingGrades },
  computed: {
    hash() {
      return this.$store.getters.getHash
    },
    successText() {
      return this.currentRating === gradesValues.idea
        ? 'Ваше предложение успешно отправлено, спасибо!'
        : 'Ваш комментарий успешно отправлен, спасибо!'
    }
  }
}
</script>

<style scoped lang="sass">
.calculator-rating
  // grid-column-start: 2
  $w-s: calc(100% - 48px)
  $w-l: rem(960)
  +media((max-width: (0: $w-s, 992: $w-l)))
  +media((margin: (0: rem(32) auto, 768: rem(51) auto rem(64))))
  text-align: left
  &__title
    @extend %20
    font-weight: bold
    margin-bottom: rem(17)
</style>
