<template>
  <div @click="handler" :class="classes">
    <div class="showAll__text">{{ btnText }}</div>
    <button class="showAll__btn">
      <img src="@/assets/img/showMore.svg" alt="↓" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isShowAll: {
      type: Boolean,
      required: true
    }
  },
  name: 'ExpandBtn',
  methods: {
    handler() {
      this.$emit('toggle')
    }
  },
  computed: {
    classes() {
      return {
        showAll: true,
        'showAll--active': this.isShowAll
      }
    },
    btnText() {
      return this.isShowAll ? 'Скрыть' : 'Показать все материалы'
    }
  }
}
</script>

<style scoped lang="sass">
.showAll

  background: $semi-white
  border: rem(1) solid $ec
  box-sizing: border-box
  border-radius: rem(4)
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  cursor: pointer
  +media((margin: (0: rem(24) rem(-15) 0, 768: rem(34) rem(-15) 0)))
  +media((padding: (320: rem(16) rem(20) rem(16) rem(26), 768: rem(25) rem(43) rem(25) rem(40))))
  &__text
    font-style: normal
    font-weight: 600
    font-size: rem(14)
    line-height: rem(17)
    color: $red
  &__btn
    border: none
    background: none
    box-shadow: none
    height: 100%
    cursor: pointer
    outline: none
    display: flex
    flex-direction: row
    align-items: center
    img
      transition: .5s
  &--active
    .showAll
      &__btn
        img
          transform: rotate(180deg)
</style>
