<template>
  <div class="item">
    <router-link :to="`/details/${sectorKit.index}`" class="link">
      {{ sectorKit.sectorName }}
    </router-link>
    <div class="text">{{ block.C }} мм</div>
    <div class="text">{{ block.D }} мм</div>
    <div class="text">{{ block.E }} мм</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    block: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  name: 'BodyItem',
  computed: {
    ...mapState({
      sectors: state => state.sectors
    }),
    sectorKit() {
      const { uid } = this.block
      const index = this.sectors.findIndex(sector => sector.uid === uid)
      const sectorName = this.sectors.find(sector => sector.uid === uid).sectorName
      return { index, sectorName }
    }
  }
}
</script>

<style scoped lang="sass">
.tables
  &__item
    &-body
      .item
        display: grid
        align-items: center
        $t-l: repeat(4, 1fr)
        $t-s: rem(100) repeat(3, rem(120))
        +media((grid-template-columns: (0: $t-s, 535: $t-l)))
        grid-column-gap: rem(15)
        +media((padding: (0: rem(12) rem(15), 768: rem(12) rem(40))))
        &:not(:last-child)
          box-shadow: inset 0 rem(-1) 0 $ec
        .link, .text
          margin-right: auto
        .link
          text-overflow: ellipsis
          white-space: nowrap
          overflow: hidden
          max-width: 100%
          background: $red
          transition: .5s
          color: $white
          padding: rem(7)
          font-size: rem(12)
          font-weight: bold
          line-height: 100%
          text-decoration: none
          border-radius: rem(2)
          @media(any-hover: hover)
            &:hover
              background: $red-hover
        .text
          text-align: left
          font-weight: normal
          +media((font-size: (0: rem(14), 768: rem(16))))
          line-height: 100%
          color: $black
</style>
