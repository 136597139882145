<template>
  <div class="head__titles">
    <div class="title">Участки</div>
    <div class="title">Угловая зона (C)</div>
    <div class="title">Парапетная зона (D)</div>
    <div class="title">Центральная зона (E)</div>
  </div>
</template>

<script>
export default {
  name: 'HeadTitles'
}
</script>

<style scoped lang="sass">
.head
  &__titles
    border-bottom: rem(1) solid $ec
    background: $white
    +media((padding: (0: rem(24) rem(15), 768: rem(24) rem(40))))
    display: grid
    $t-l: repeat(4, 1fr)
    $t-s: rem(100) repeat(3, rem(120))
    +media((grid-template-columns: (0: $t-s, 535: $t-l)))
    grid-column-gap: rem(15)
    border-radius: 0 0 rem(4) rem(4)
    .title
      text-align: left
      font-style: normal
      font-weight: 500
      font-size: rem(14)
      line-height: 140%
      color: $light_black
</style>
